.professional-summary-container {
    width: 85% !important;
    margin: 50px auto;
    padding: 20px;
    background-color: #000000;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    color: black;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
}

section {
    margin-bottom: 40px;
}

h2 {
    color: #e0e0e0;
    font-size: 1.8rem;
    margin: 0;
    font-family: Arial, sans-serif;
}

.professional-summary p {
    font-size: 1rem;
    color: #d0d0d0;
    text-align: justify;
    font-family: Arial, sans-serif;
    background-color: #242424;
    border-radius: 5px;
    padding: 1rem;
    box-sizing: border-box;
    overflow: hidden;
    word-wrap: break-word;
}

.professional-summary-heading {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    line-height: inherit !important;
    padding-bottom: 10px;
}

.professional-overview-heading {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    line-height: inherit !important;
    padding-bottom: 10px;
}

.overview {
    padding: 0;
}

.overview-items {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.overview-item {
    text-align: center;
    background-color: #282828;

    border-radius: 5px;
    flex: 1;
}

.overview-item h3 {
    font-size: 3rem;
    color: #00b5ad;
    margin: 0;
}

.overview-item p {
    font-size: 1rem;
    color: #b0b0b0;
}

.container-1 {
    border-radius: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%; /* Make the container scale with its parent */
    height: auto; /* Automatically adjust height */
}

/* Watermark Styles */
.watermark-1 {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.watermark-text-1 {
    position: absolute; /* Ensure it's positioned independently */
    font-size: 15vw; /* Scale with viewport width */
    font-weight: bold;
    color: rgba(255, 255, 255, 0.05); /* Light transparent color for watermark */
    z-index: 1;
    pointer-events: none; /* Makes sure it's not selectable or interactable */
    left: 67%;
    line-height: normal;
    white-space: nowrap; /* Prevent text from wrapping */
}


/* Content Styles */
.content-1 {
    position: relative;
    z-index: 2;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%; /* Scale with container */
}

.main-text-1 {
    font-size: 5vw; /* Adjust font size based on viewport */
    font-weight: bold;
    color: #00b5ad; /* Highlight color */
    display: block;
    letter-spacing: 0.5vw; /* Adjust spacing for scaling */
}

.content-1 p {
    margin: 0;
    font-size: 1.5vw; /* Scale text dynamically */
    line-height: 1.4;
    color: #d0d0d0;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 0.2vw; /* Adjust for responsiveness */
    word-spacing: 0.5vw;
}