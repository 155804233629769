/* Certification Section */
.certification-section {
    width: 85%;
    margin: 40px 0 0 0; /* Add spacing above and below the section */
  }
  
  /* Certification Title */
  .certification-title {
    font-size: 1.8rem;
    color: #e0e0e0;
    display: flex;
    margin: 0;
    padding-left: 10px;
    font-weight: bold;
    gap: 5px;
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  /* Certification Container */
  .certification-container {
    background-color: #282828;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    margin: 20px 0 0 20px; /* Indent to make it look like a subsection */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for better visibility */
  }
  
  /* Certification Item */
  .certification-item {
    font-size: 1rem;
    color: #a0a0a0;
  }
  
  .certification-link {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 400;
    color: #00b5ad !important;
  }