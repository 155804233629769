/* Education Section */
.education-section {
    width: 85%;
    margin: 40px 0 0 0; /* Add spacing above and below the section */
  }
  
  /* Education Title */
  .education-title {
    font-size: 1.8rem;
    color: #e0e0e0;
    display: flex;
    margin: 0;
    padding-left: 10px;
    font-weight: bold;
    gap: 5px;
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  /* Education Container */
  .education-container {
    background-color: #121212;
    color: #fff;
    padding: 30px;
    border-radius: 10px;
    max-width: 800px;
    margin: 20px 0 0 20px; /* Indent to make it look like a subsection */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for better visibility */
  }
  
  /* Education Card */
  .education-card {
    background-color: #282828 !important;
    border-radius: 10px !important;
    padding: 20px !important;
    margin-bottom: 20px !important;
    display: flex;
    flex-direction: column;
  }
  
  /* Education Header */
  .education-header {
    display: flex;
    align-items: flex-start;
  }
  
  .education-icon {
    font-size: 2rem;
    color: #38e5e5;
    margin-right: 15px;
  }
  
  .education-details {
    flex-grow: 1;
  }
  
  .education-degree {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .education-university,
  .education-location,
  .education-date {
    font-size: 0.9rem;
    color: #a0a0a0;
    margin: 5px 0;
  }

  /* Description Link */
.education-description-link {
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: #00b5ad !important;
}

.education-description {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 2px solid #555; /* Adds a top border */
}

.education-description ul {
  list-style-type: disc; /* Adds bullet points */
  margin-left: 20px;
}

.education-description li {
  margin-bottom: 10px; /* Adds spacing between bullet points */
  color: #e0e0e0;
}