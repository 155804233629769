.contact-form-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.contact-form h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.contact-form p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.contact-header h2 {
  font-size: 1.5rem;
}

.contact-reason .reason-description {
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
  color: #666;
}

.contact-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.contact-footer p {
  font-size: 1rem;
  color: #333;
}

.contact-footer button {
  padding: 10px 20px;
}

button {
  background-color: #666;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

button:hover {
  background-color: #333;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it appears above other elements */
}

.dialog-box {
  background: white;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 1.2rem;
  color: #333;
}