/* Skills Section */
.skills-section {
    width: 85%;
    margin: 40px 0 40px 0; /* Add spacing above and below the section */
  }
  
  /* Skills Title */
  .skills-title {
    font-size: 1.8rem;
    color: #e0e0e0;
    display: flex;
    margin: 0;
    padding-left: 10px;
    font-weight: bold;
    gap: 5px;
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  /* Skills Container */
  .skills-container {
    background-color: #282828;
    color: #fff;
    padding: 30px;
    border-radius: 10px;
    max-width: 800px;
    margin: 20px 0 0 20px; /* Indent to make it look like a subsection */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for better visibility */
  }
  
  /* Skills Grid */
  .skills-grid {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .skills-column {
    flex: 1;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  /* Skill Item */
  .skill-item {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #00b5ad;
    margin-bottom: 10px;
  }
  
  .skill-item i {
    margin-right: 8px !important;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .skills-grid {
      flex-direction: column;
      gap: 20px;
    }
  }
  