/* Work History Section */
.work-history-section {
  width: 85%;
  margin: 0 0; /* Add spacing above and below the section */
}

/* Work History Title */
.work-history-title {
  font-size: 1.8rem;
  color: #e0e0e0;
  display: flex;
  margin: 0;
  padding-left: 10px;
  font-weight: bold;
  gap: 5px;
  align-content: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}

/* Work History Container */
.work-history-container {
  background-color: #121212;
  color: #fff;
  padding: 30px;
  border-radius: 10px;
  max-width: 800px;
  margin: 20px 0 0 20px; /* Indent to make it look like a subsection */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Subtle shadow for better visibility */
}

/* Job Card */
.job-card {
  background-color: #282828 !important;
  border-radius: 10px !important;
  padding: 20px !important;
  margin-bottom: 20px !important;
  display: flex;
  flex-direction: column;
}

/* Job Header */
.job-header {
  display: flex;
  align-items: flex-start;
}

.job-icon {
  font-size: 2rem;
  color: #00b5ad;
  margin-right: 15px;
}

.job-details {
  flex-grow: 1;
}

.job-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
}

.job-company,
.job-location,
.job-date {
  font-size: 0.9rem;
  color: #a0a0a0;
  margin: 5px 0;
}

/* Description Link */
.job-description-link {
  margin-top: 10px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  color: #00b5ad !important;
}

.job-description {
  margin-top: 15px;
  padding-top: 10px;
  border-top: 2px solid #555; /* Adds a top border */
}

.job-description ul {
  list-style-type: disc; /* Adds bullet points */
  margin-left: 20px;
}

.job-description li {
  margin-bottom: 10px; /* Adds spacing between bullet points */
  color: #e0e0e0;
}