.profile-header-container {
    background-color: #000;
    width: 85%;
    border-radius: 50px;
    display: flex;
    flex-direction: column; /* Stack content if needed */
    align-items: center;
    padding: 20px; /* Add space inside the container */
    margin-bottom: 20px; /* Add spacing between sibling components */
}

/* Header Section */
.profile-header {
    position: relative;
    width: 100%;
}

.header-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.certification-badge {
    position: absolute;
    top: 10px; /* Adjust the top spacing as needed */
    right: 10px; /* Adjust the right spacing as needed */
    z-index: 10; /* Ensure it appears above other elements */
    width: 100px; /* Set the badge size */
    height: 100px; /* Adjust the size according to your requirements */
    border-radius: 50%; /* Make it circular */
    padding: 5px; /* Add padding inside the badge if needed */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3); /* Optional: Add shadow for better visibility */
}

/* Profile Details Section */
.profile-details {
    background-color: #242424;
    border-radius: 0 0 5px 5px;
}

.profile-details .container {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
}

.profile-details .container .grid {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}


.profile-details .container .grid .contact-me {
    text-align: center;
    display: flex; /* Ensures the container centers the button */
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Centers the container horizontally */
    width: 100%; /* Make the container responsive */
}

.profile-picture-container {
    position: relative;
    border-radius: 50% !important; /* Makes the image circular */

    width: 100%;
    height: auto; /* Maintain aspect ratio */
    max-width: 300px; /* Optional: Limit the maximum width */
    max-height: 300px; /* Optional: Limit the maximum height */
    object-fit: cover; /* Optional: Crop the image if needed */
    display: block; /* Center alignment in some cases */
    margin: 0 auto; /* Optional: Center horizontally */
}

.profile-picture {
    width: 100%;
    height: 100%;
    /* border: 4px solid white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); */
    border: 3px solid white; /* Adds a white border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.9); /* Adds shadow for a floating effect */
    border-radius: 50% !important; /* Makes the image circular */

    width: 100%;
    height: auto; /* Maintain aspect ratio */
    max-width: 300px; /* Optional: Limit the maximum width */
    max-height: 300px; /* Optional: Limit the maximum height */
    object-fit: cover; /* Optional: Crop the image if needed */
    display: block !important; /* Center alignment in some cases */
    margin: 0 auto; /* Optional: Center horizontally */
}

.profile-name {
    color: white !important;
    text-align: center !important;
    font-size: 2rem !important;
    font-weight: bold !important;
    margin-top: 1rem !important;
    font-family: 'Neuemontreal', sans-serif !important;
    letter-spacing: 1px;
}

.profile-interest {
    color: white !important;
    text-align: center !important;
    font-size: 1rem !important;
    font-weight: 100 !important;
    margin-top: 0 !important;
    font-family: 'Neuemontreal', sans-serif !important;
    letter-spacing: 1px;
}

.profile-meta {
    color: lightgray;
    text-align: center;
    font-size: 1rem;
}

.profile-meta .icon {
    margin-right: 5px;
}

.profile-meta-linkedin {
    color: #00b5ad;
}

.profile-meta-linkedin:hover {
    color: teal;
}

.contact-me-button {
    display: inline-block; /* Makes the link behave like a button */
    padding: 8px 15%; /* Use percentage padding for dynamic sizing */
    border-radius: 100px;
    font-size: 1.2vw; /* Use viewport width for font size */
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    color: white; /* White text color */
    background-color: #00b5ad; /* Teal background */
    transition: background-color 0.3s ease, color 0.3s ease; /* Add hover effects */
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    max-width: 200px; /* Limit the maximum width */
    min-width: 100px; /* Set a minimum width */
}
  
  .contact-me-button:hover {
    background-color: teal; /* Teal background on hover */
    color: white; /* White text on hover */
  }

  @media (max-width: 768px) {
    .contact-me-button {
        padding: 10px 4%; /* Reduce padding for smaller screens */
        font-size: 1rem; /* Adjust font size */
        max-width: 150px; /* Reduce max-width on smaller screens */
    }
}