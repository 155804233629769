/* Timeline Section */
.timeline-section {
    margin: 40px 0 0 0;
    /* Add spacing around the section */
    width: 85% !important;
}

/* Timeline Title */
.timeline-title {
    font-size: 1.8rem;
    color: #e0e0e0;
    display: flex;
    margin: 0;
    padding-left: 10px;
    font-weight: bold;
    gap: 5px;
    align-content: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

/* Timeline Container */
.timeline-container {
    position: relative;
    max-width: 800px;
    margin: 20px auto;
    /* Center the container with proper spacing */
    padding: 20px;
    /* Add padding to fix spacing issues */
    background-color: transparent;
    display: flex
;
    flex-direction: column;
}

/* Timeline Line */
.timeline-line {
    position: absolute;
    left: 50%;
    /* Center the vertical line */
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #555;
    z-index: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    /* Prevent interaction */
}

.timeline-content-line {
    position: absolute;
    left: 50%;
    /* Center the vertical line */
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #555;
    z-index: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    /* Prevent interaction */
    justify-content: center;
}

.timeline-content-line .timeline-bullet {
    position: relative;
    z-index: 1;
    background-color: #121212;
    border-radius: 50%;
    font-size: 1.2rem; 
    margin: 0 !important;
    height: auto;
    color: white;
}

/* Timeline Item */
.timeline-item {
    position: relative;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.timeline-item:hover .timeline-content {
    background-color: #1e1e1e;
}

.timeline-item:hover .timeline-bullet {
    color: #00b5ad;
}

.timeline-item.left {
    align-items: flex-start;
}

.timeline-item.right {
    align-items: flex-end;
}

/* Align Items */
.timeline-content.left {
    width: 45%;
    left: 0;
}

.timeline-content.right {
    width: 45%;
    left: 55%;
}

/* Timeline Content */
.timeline-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    border: 2px solid #555;
}

.timeline-content-header {
    display: flex;
    flex-direction: column;
}

.timeline-icon-boundary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    background-color: #5a5a5a; /* Match the container background */
    border-radius: 8px; /* Rounded corners for the boundary */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional shadow for depth */
    margin-right: 10px; /* Space between icon and text */
}

.timeline-icon-boundary .timeline-icon {
    font-size: 1.7rem !important;
    color: #00b5ad;
    margin-bottom: 10px;
}

.timeline-title-item {
    font-size: 1.2rem !important;
    font-weight: bold;
    margin: 0;
    color: #fff;
}

.timeline-company,
.timeline-date {
    font-size: 0.9rem;
    color: #a0a0a0;
    margin: 5px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .timeline-item {
        width: 100%;
        /* Full width on mobile */
        left: 0;
        transform: none;
    }

    .timeline-line {
        left: 20px;
        /* Adjust line alignment for mobile */
    }
}

.timeline-dot {
    width: 12px;
    height: 12px;
    background-color: #555;
    border-radius: 50%;
    margin: 20px 0;
    transition: background-color 0.3s ease, transform 0.3s ease;
    pointer-events: auto;
    /* Enable hover interaction */
}