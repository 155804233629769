* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.revised-header {
  display: flex !important;
  justify-content: space-between;
  /* Push left and right sections apart */
  align-items: center;
  /* Vertically center items */
  background: #2e2e2e;
  /* Header background color */
  padding: 1rem 2rem;
  /* Add space around header */
  color: #fff;
  /* Text color */
  flex-wrap: nowrap;
}

.revised-header .logo {
  font-size: 1.5rem;
  /* Logo font size */
  font-weight: bold;
  /* Make logo bold */
  text-transform: uppercase;
  /* Uppercase text */
  letter-spacing: 2px;
  /* Add spacing between letters */
  display: flex;
  /* Keep logo items inline */
  align-items: center;
  /* Align logo text and image vertically */
}

.revised-header .logo img {
  max-height: 50px;
  /* Adjust logo image height */
  vertical-align: middle;
  /* Align image with text */
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Space between the logo and text */
}

.circular-logo {
  width: 50px;
  /* Adjust as needed */
  height: 50px;
  /* Make height equal to width for a circle */
  border-radius: 50%;
  /* Makes the image circular */
  border: 2px solid rgba(0, 0, 0, 0.8);
  /* Optional: Add a border with fade-to-black effect */
  object-fit: cover;
  /* Ensures the image fits well within the circle */
}

.site-name {
  font-size: 1.3rem;
  color: #ababab;
  font-weight: 700;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}


/* Right Section (Navigation/Options) */
.revised-header .nav {
  display: flex !important;
  /* Arrange items in a row */
  gap: 1.5rem;
  /* Space between navigation items */
}

.revised-header .nav a {
  text-decoration: none;
  /* Remove underline */
  color: #fff;
  /* White text */
  font-size: 1rem;
  /* Font size */
  transition: color 0.3s ease;
  /* Smooth color change on hover */
  color: #00b5ad;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.revised-header .nav a:hover {
  color: teal;
  /* Highlight link on hover */
}


.footer {
  background: rgb(0, 0, 0); /* Footer background color */
  color: #fff; /* Footer text color */
  text-align: center; /* Centered text */
  padding: 1rem 0; /* Footer padding */
  font-size: 0.9rem; /* Font size */
  border-top: 2px solid #333; /* Add a line at the top with a solid color */
}

.body {
  font-family: Arial, sans-serif;
  /* Global font */
  line-height: 1.6;
  color: #333;
  /* Text color */
  background-color: black;
  margin: 0;
  display: flex;
  flex-direction: column;
  /* Allows header, main, footer stacking */
  min-height: 100vh;
}